<template>
  <div class="segment">
    <a-row class="align-items-center mb-4 justify-items-center d-flex"
      style="margin: -30px; padding: 15px 30px 22px 30px; background: #fff">
      <a-col :span="18">
        <a-breadcrumb>
          <a-breadcrumb-item><a style="color: #1890FF" @click="redirectToSegments">Segments</a></a-breadcrumb-item>
          <a-breadcrumb-item><a style="color: rgba(0, 0, 0, 0.45)" href="javascript:;">{{ segmentName
              }}</a></a-breadcrumb-item>
        </a-breadcrumb>
        <div class="d-flex align-items-end mt-2 segment__title">
          <div>
            <div :class="{'borderless-input' : isTitleChangeDisabled, 'custom-input' : !isTitleChangeDisabled}">
              <a-input ref="segmentNameInput" :disabled="isTitleChangeDisabled" class="mb-0 mr-3" v-model="segmentName" :style="{ width: `${segmentName.length * 1.3 + 9}ch` }">
                <template #suffix> <a class="font-size-18 text-primary mt-1"
                    @click="toggleEditSegmentName" style="line-height: 2;">  <a-icon :type="isTitleChangeDisabled ? 'edit' : 'check'" /></a></template>
              </a-input>
            </div>
          </div>
        </div>
      </a-col>
      <a-col :span="6" class="text-right" style="display: flex; justify-content: flex-end">
        <div>
          <a-button type="primary" size="large" style="font-size: 16px; font-weight: 300; border-radius: 2px"
            @click="addUser">
            <a-icon type="plus" /> New user
          </a-button>
        </div>
      </a-col>
    </a-row>
    <div style="padding: 24px; background-color: #fff">
      <a-card>
        <div class="d-flex align-items-center justify-content-between mb-4">
          <div style="flex-grow: 1">
            <a-input-search v-model="search" placeholder="Input search text" enter-button @change="tableUpdate" />
          </div>
        </div>
        <a-table :columns="columns" :data-source="accounts" :rowKey="(record, index) => index" :loading="loading"
          :pagination="pagination" @change="changePagination" class="segments__table operations-table">
          <template slot="createdAt" slot-scope="text, record">
            <span class="mr-2">{{ $moment(record.created_at).format('DD.MM.YY') }}</span>
            <span>{{ $moment(record.created_at).format('HH:mm') }}</span>
          </template>
          <template slot="actions" slot-scope="text, record">
            <div class="row-operations">
              <span>
                <a-popconfirm placement="left" :disabled="record.is_default" title="Sure to delete this user?"
                  @confirm="() => deleteUser(record.id)">
                  <a-icon class="font-size-18 text-danger delete-icon" type="delete" />
                </a-popconfirm>
              </span>
            </div>
          </template>
        </a-table>
      </a-card>
    </div>
    <a-modal v-model="addUserModal" title="Add user" :footer="null" width="450px" :destroyOnClose="true">
      <UserModal :segment-id="segmentId" @closeUserModal="closeUserModal" @addNewUser="handleAddUser" />
    </a-modal>
  </div>
</template>
<script>

import ViewscreenService from '@/services/api/apiViewscreenService'
import UserModal from '@/components/custom/modals/userModal.vue'
import debounce from 'lodash.debounce'

export default {
  name: 'index',
  components: { UserModal },
  data: () => ({
    segmentId: null,
    segment: null,
    loading: false,
    accounts: null,
    search: '',
    addUserModal: false,
    segmentName: '',
    isTitleChangeDisabled: true,
    showCheckIcon: false,
    pagination: {
      pageSize: 25,
      current: 1,
      total: 0,
    },
    columns: [
      {
        title: 'ID',
        dataIndex: 'id',
        width: '19%',
      },
      {
        title: 'Created',
        dataIndex: 'created_at',
        width: '19%',
        scopedSlots: { customRender: 'createdAt' },
      },
      {
        title: 'Login',
        dataIndex: 'login',
        width: '19%',
      },
      {
        title: 'Bundle ID',
        dataIndex: 'bundle_id',
        width: '19%',
      },
      {
        title: 'Actions',
        width: '9%',
        scopedSlots: { customRender: 'actions' },
      },
    ],
  }),
  computed: {
    showTitleIcon() {
      return this.isTitleChangeDisabled
    },
  },
  created() {
    this.segmentId = this.$route.params.id
    if (this.segmentId) {
      this.getSegmentUsers(this.segmentId)
      this.getSegment(this.segmentId)
    }
  },
  methods: {
    async getSegment(segmentId) {
      this.loading = true
      try {
        const response = await ViewscreenService.getSegment(segmentId)
        if (response.data.data) {
          this.segment = response.data.data
          this.segmentName = this.segment.name
        }
      } finally {
        this.loading = false
      }
    },
    async getSegmentUsers(segmentId, login) {
      this.loading = true
      try {
        const response = await ViewscreenService.getSegmentAccounts(segmentId, login, this.pagination.current, this.pagination.pageSize)
        if (response.data.data) {
          this.accounts = response.data.data
          this.pagination.total = response.data.meta.total
        }
      } finally {
        this.loading = false
      }
    },
    tableUpdate: debounce(function () {
      this.getSegmentUsers(this.segmentId, this.search)
    }, 500),
    changePagination(pagination) {
      this.pagination = pagination
      this.getSegmentUsers(this.segmentId, this.search)
    },
    addUser() {
      this.addUserModal = true
    },
    closeUserModal() {
      this.addUserModal = false
    },
    handleAddUser(newSegment) {
      this.closeUserModal()
      this.accounts.unshift(newSegment)
    },
    async deleteUser(accountId) {
      this.loading = true
      try {
        const response = await ViewscreenService.deleteSegmentAccount(this.segmentId, accountId)
        if (response.data.success === true) {
          const deletedIndex = this.accounts.findIndex(account => account.id === accountId)
          if (deletedIndex !== -1) {
            this.accounts.splice(deletedIndex, 1)
          }
          this.$notification.success({
            message: 'User has been successfully deleted from segment',
          })
        }
      } catch (error) {
        this.$notification.error({
          message: 'Error while deleting user',
          description: error.message,
        })
      } finally {
        this.loading = false
      }
    },
    toggleEditSegmentName() {
      this.isTitleChangeDisabled = !this.isTitleChangeDisabled
      this.showCheckIcon = !this.isTitleChangeDisabled
      this.$nextTick(() => {
        if (!this.isTitleChangeDisabled) {
          const inputElement = this.$refs.segmentNameInput.$el.querySelector('input')
          if (inputElement) {
            inputElement.focus()
          }
        }
      })
      if (this.isTitleChangeDisabled) {
        this.editSegment()
      }
    },
    async editSegment() {
      try {
        const data = {
          name: this.segmentName,
        }
        const response = await ViewscreenService.updateSegment(this.segmentId, data)
        if (response.data.data) {
          this.$notification.success({
            message: 'Segment name has been successfully updated',
          })
        }
      } catch (error) {
        this.$notification.error({
          message: 'Error while updating segment name',
          description: error.message,
        })
      }
    },
    redirectToSegments() {
      this.$router.push('/segments/')
    },
  },
}
</script>
<style lang="scss">
.segment {
  &__table {
    .ant-table-header-column {
      font-weight: bold;
    }

    .delete-icon {

      &:hover,
      :focus {
        cursor: pointer;
        color: #a71d2a !important;
      }
    }
  }

  &__title {
    .input-title {
      border: 0;
      padding: 0;
      font-size: 20px;
    }

    .borderless-input {
      .ant-input {
      border: 0;
      padding: 0;
      font-size: 20px;
      }
    }

    .custom-input {
      .ant-input {
      border: 1px solid #e4e9f0;
      padding: 4px 11px;
      font-size: 20px;
      }
    }

    .ant-input-affix-wrapper.ant-input-disabled.ant-input-suffix.anticon {
      color: inherit;
      cursor: pointer;
    }

    .ant-input-disabled {
      color: #141322 !important;
      background-color: inherit !important;
      cursor: default !important;
      opacity: 1 !important;
    }
  }

  .anticon-edit {
    color: inherit !important;
    cursor: pointer !important;
  }
  .ant-table-pagination.ant-pagination {
    margin: 16px auto 0px;
  }
}
</style>
